import React, { useEffect, useState, useMemo } from "react";
import Layout from "../components/layout";
import { Container, Table } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import { format } from "date-fns";
import useWindowSize from "../utils/useWindowSize";
import Modal from "../components/home/modal"

export default function Orders() {
  const { width } = useWindowSize();
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const isBrowser = typeof window !== "undefined";
  const user = isBrowser ? JSON.parse(window.localStorage.getItem("user")) : null;

  const userId = useMemo(() => user?.id, [user]);

  useEffect(() => {
    if (!userId) {
      navigate("/");
      return;
    }

    const fetchOrders = async () => {
      try {
        const response = await fetch(`${process.env.GATSBY_API_URL}/checkout/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch orders');
        }
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        setError('Failed to load orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId]); 

  if (loading) {
    return (
      <Layout>
        <Container style={{ paddingTop: "4rem" }}>
          <h1 style={{ paddingTop: "80px" }}>Loading...</h1>
        </Container>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Container style={{ paddingTop: "4rem" }}>
          <h1 style={{ paddingTop: "80px" }}>{error}</h1>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Modal />
      <Container
        style={{
          paddingTop: "4rem",
          minHeight: `${width < 768 ? "70vh" : "70vh"}`,
        }}
      >
        <h1 style={{ paddingTop: "80px" }}>Your Orders</h1>
        <div>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Drug</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total Cost</th>
                <th>Date Ordered</th>
                <th>Processed</th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 ? (
                orders.flatMap(order =>
                  order.orderItems.map(item => {
                    const price = item.price || 0;
                    const totalCost = (item.quantity || 0) * price;
                    return (
                      <tr key={item._id}>
                        <td>
                          <Link
                            to={`/product/${item.productTradeName
                              ?.replace(/ /g, "+")
                              .toLowerCase()}`}
                          >
                            {item.productTradeName}
                          </Link>
                        </td>
                        <td>{item.quantity}</td>
                        <td>{price.toFixed(2)}</td>
                        <td>{totalCost.toFixed(2)}</td>
                        <td>
                          {format(new Date(order.createdAt), "yyyy-MM-dd hh:mm aa")}
                        </td>
                        <td>{order.status === "processed" ? "Yes" : "No"}</td>
                      </tr>
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan="6">No orders available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Container>
    </Layout>
  );
}
